// docs https://github.com/michalsnik/aos
import AOS from "aos";

import {
  initCarousels,
  initAccordions,
  initDrawers,
  initDropdowns,
} from "flowbite";

AOS.init({
  once: true,
  duration: 500,
});

const navbar = document.querySelector("nav.navbar");

function toggleNavbar() {
  const bodyScrollTop = Math.max(
    document.scrollingElement.scrollTop,
    document.documentElement.scrollTop
  );

  if (bodyScrollTop >= 78) {
    navbar.classList.add("is-scrolled");
  } else {
    navbar.classList.remove("is-scrolled");
  }
}

document.addEventListener("scroll", toggleNavbar, { passive: true });

window.addEventListener("load", toggleNavbar);

initAccordions();
initDrawers();
initDropdowns();
initCarousels();

async function supportsEncode() {
  const fallbackclass = "img-fallback";

  if (!createImageBitmap) return fallbackclass;

  const avifData =
      "data:image/avif;base64,AAAAIGZ0eXBhdmlmAAAAAGF2aWZtaWYxbWlhZk1BMUEAAADybWV0YQAAAAAAAAAoaGRscgAAAAAAAAAAcGljdAAAAAAAAAAAAAAAAGxpYmF2aWYAAAAADnBpdG0AAAAAAAEAAAAeaWxvYwAAAABEAAABAAEAAAABAAABGgAAABYAAAAoaWluZgAAAAAAAQAAABppbmZlAgAAAAABAABhdjAxQ29sb3IAAAAAamlwcnAAAABLaXBjbwAAABRpc3BlAAAAAAAAAAEAAAABAAAAEHBpeGkAAAAAAwgICAAAAAxhdjFDgSAAAAAAABNjb2xybmNseAACAAIABoAAAAAXaXBtYQAAAAAAAAABAAEEAQKDBAAAAB5tZGF0EgAKBzgADlAgIGkyCR/wAABAAACvcA==",
    webpData =
      "data:image/webp;base64,UklGRiQAAABXRUJQVlA4IBgAAAAwAQCdASoCAAEAAQAcJaQAA3AA/v3AgAA=",
    avifblob = await fetch(avifData).then((r) => r.blob());
    
  return createImageBitmap(avifblob)
    .then(() => "avif")
    .catch(async (e) => {
      console.log("avif failed:", e);
      const webpblob = await fetch(webpData).then((r) => r.blob());
      return createImageBitmap(webpblob).then(() => "webp");
    })
    .catch(() => fallbackclass);
}

(async () => {
  document.body.classList.add(await supportsEncode());
})();

document.addEventListener('submit', function (event) {
  if (event.target.id !== "email-form") return;

  event.preventDefault();

  const formData = new FormData(event.target);
  const email = formData.get("email");

  if (!email) return;

  submitHubspotForm({ email });
})

  const HUBSPOT_URL =
    "https://api.hsforms.com/submissions/v3/integration/submit/20692225/ad8d5eb8-7dcf-422c-bf39-e9dce7daf99c";

function submitHubspotForm({email}) {
  // Create the new request
  const xhr = new XMLHttpRequest();

  // Example request JSON:
  const data = {
    submittedAt: Date.now(),
    fields: [
      {
        objectTypeId: "0-1",
        name: "email",
        value: email,
      },
    ],
    context: {
      pageUri: window.location.href,
      pageName: document.title,
    },
  };

  xhr.open("POST", HUBSPOT_URL);
  xhr.setRequestHeader("Content-Type", "application/json");

  xhr.onreadystatechange = function () {
    if (xhr.readyState == 4 && xhr.status == 200) {
      document.getElementById("email-form").remove();
      document.getElementById("email-form-success").classList.remove("hidden");
    } else if (xhr.readyState == 4 && xhr.status >= 400) {
      console.error(xhr.responseText);
    }
  };
  
  xhr.send(JSON.stringify(data));
}
